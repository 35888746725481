import React from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navigation from "../../components/navigation/Navigation";
import TicketDetailsComponent from "../../components/supportTicket/TicketDetailsComponent";
import { useParams } from "react-router-dom";

const TicketDetails = () => {
  const { id } = useParams();

  return (
    <div id="profilepage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="profile-container">
            <TicketDetailsComponent ticketId={id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketDetails;
