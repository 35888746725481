// apiHelper.js

import axios from "axios";

// Replace with your API base URL
// const baseURL = "http://127.0.0.1:8000/api/v1/";
const baseURL = "https://api.tranzift.com/api/v1/";

const api = axios.create({
  baseURL,
});

const apiHelper = async (endpoint, method = "GET", data = null, headers = {}) => {
  try {
    const response = await api({
      method,
      url: endpoint,
      data,
      headers,
    });

    return response.data;
  } catch (error) {
    // Handle error (you can log it, show a message, etc.)
    console.error("API Error:", error);
    throw error;
  }
};

export default apiHelper;
