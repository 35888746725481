import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Navigation from "../../components/navigation/Navigation";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";
import CategoryDetailsComp from "../../components/category/CategoryDetailsComp";
import { useParams } from "react-router-dom";
import apiHelper from "../../utils/apiHelper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./categories.css";

const CategoryDetails = () => {
  const [img, setImg] = useState("");

  const [productsInTheCategory, setProductsInTheCategory] = useState([]);
  const [excludedProducts, setExcludedProducts] = useState([]);

  const [categoryName, setCategoryName] = useState("");
  const [categoryDesc, setCategoryDesc] = useState("");

  const id = useParams();

  const fetchData = async () => {
    try {
      const result = await apiHelper(`categories/get-category/${id.id}`);

      setExcludedProducts(result.excludedProducts);
      setProductsInTheCategory(result.includedProducts);

      setCategoryName(result.category.categoryName);
      setImg(result.category.categoryImg.desktopImg);
      setCategoryDesc(result.category.catgoryDesc);
    } catch (error) {
      // Handle error
    }
  };

  const addProductToCategory = async (productid) => {
    try {
      await apiHelper(`categories/addproduct/${id.id}/${productid}`, "PATCH");
      fetchData();
    } catch (error) {
      // Handle error
    }
  };

  const removeProductFromCategory = async (productid) => {
    try {
      await apiHelper(`categories/removeproduct/${id.id}/${productid}`, "PATCH");
      fetchData();
    } catch (error) {
      // Handle error
    }
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const categoryData = new FormData();

      categoryData.append("categoryName", categoryName);
      categoryData.append("catgoryDesc", categoryDesc);
      categoryData.append("categoryImg", img);

      console.log(categoryData);

      const data = await apiHelper(`categories/update-category/${id.id}`, "PATCH", categoryData);
      if (data.status === "success") {
        toast.success("Category Updated Sucessfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="homepage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="card-container dashBoard_cardContainer">{categoryName}</div>
          <div className="cateDetailsContainer">
            <div className="left-side">
              <form action="" encType="multipart/form-data" onSubmit={formSubmitHandler}>
                <div className="input-form_container">
                  <div className="input-form">
                    <label htmlFor="cname">Category Name :</label>
                    <input
                      type="text"
                      id="cname"
                      name="cname"
                      value={categoryName}
                      onChange={(e) => setCategoryName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="input-form_flex-container">
                  <div className="input-form">
                    <label htmlFor="cstatus">Category Status :</label>
                    <select name="status" id="status">
                      <option value="true">Active</option>
                      <option value="false">Deactive</option>
                    </select>
                  </div>

                  <div className="input-form">
                    <label htmlFor="cimg">Upload Category Image :</label>
                    <input type="file" id="cimg" name="cimg" onChange={(e) => setImg(e.target.files[0])} />
                  </div>
                </div>

                <div className="input-form_container">
                  <div className="input-form">
                    <label htmlFor="cname">Category Name* :</label>
                    <textarea value={categoryDesc} onChange={(e) => setCategoryDesc(e.target.value)} />
                  </div>
                </div>

                <div className="input-form_container">
                  <div className="input-form">
                    <Button type="submit" sx={{ my: 2 }} variant="outlined">
                      Edit Product
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            <div className="right-side">
              <div className="preview_img_container">
                <h3 style={{ margin: "30px 0px", fontSize: "14px", textAlign: "center" }}>Image Preview</h3>
                <img
                  src={img ? img : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"}
                  alt="preview-img"
                />
              </div>
            </div>
          </div>

          <div className="category_giftCard_container">
            <CategoryDetailsComp
              type="remove"
              heading="Products in the category"
              data={productsInTheCategory}
              removeProductFromCategory={removeProductFromCategory}
            />
            <CategoryDetailsComp
              type="add"
              heading="Excluded products"
              data={excludedProducts}
              addProductToCategory={addProductToCategory}
            />
          </div>
          <ToastContainer />

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default CategoryDetails;
