import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Button, Paper } from "@mui/material";

const CategoryDetailsComp = (props) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      borderBottom: "4px #dddddd solid",
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: "1px #222222 solid",
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <div className="table">
      <p className="table-heading">{props.heading}</p>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>S.No.</StyledTableCell>
              <StyledTableCell align="center">Product SKU</StyledTableCell>
              <StyledTableCell align="center">Product Name</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.data.length === 0 && (
              <StyledTableRow>
                <StyledTableCell component="th" scope="row" style={{ color: "red" }}>
                  -
                </StyledTableCell>
                <StyledTableCell align="center" style={{ color: "red" }}>
                  NO
                </StyledTableCell>
                <StyledTableCell align="center" style={{ color: "red" }}>
                  PRODUCTS
                </StyledTableCell>
                <StyledTableCell align="center" style={{ color: "red" }}>
                  FOUND
                </StyledTableCell>
              </StyledTableRow>
            )}

            {props.data.length > 0 &&
              props.data.map((element, indx) => (
                <StyledTableRow key={element._id}>
                  <StyledTableCell component="th" scope="row">
                    {indx + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">{element.sku}</StyledTableCell>
                  <StyledTableCell align="center">{element.nameOnWoohoo}</StyledTableCell>
                  <StyledTableCell align="center">
                    {props.type === "add" ? (
                      <Button
                        size="small"
                        color="success"
                        onClick={() =>
                          props.addProductToCategory(props.callType === "coupon" ? element.sku : element._id)
                        }
                      >
                        Add
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        color="error"
                        onClick={() =>
                          props.removeProductFromCategory(props.callType === "coupon" ? element.sku : element._id)
                        }
                      >
                        Remove
                      </Button>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CategoryDetailsComp;
