import React from "react";
import LoginForm from "../../components/Forms/LoginForm";

const Login = (props) => {
  return (
    <div id="login" className="login-page">
      <div className="right-container">
        <div className="rest">
          <LoginForm heading="Login To Access Your Dashboard" />
        </div>
      </div>
    </div>
  );
};

export default Login;
