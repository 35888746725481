import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { blue } from "@mui/material/colors";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { IconButton, Tooltip } from "@mui/material";
import { Close, Delete, Done } from "@mui/icons-material";

import Navigation from "../../components/navigation/Navigation";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";

import apiHelper from "../../utils/apiHelper";

const Offer = () => {
  const [allCoupons, setAllCoupons] = useState([]);

  const fetchAllCoupons = async () => {
    try {
      const res = await apiHelper(`coupon/getallcoupons`);

      if (res.status === "success") {
        setAllCoupons(res.coupons);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateTheCoupon = async (id, type) => {
    try {
      const res = await apiHelper(`coupon/${id}`, "patch", { isActive: type });

      if (res.status === "success") {
        fetchAllCoupons();
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllCoupons();
  }, []);

  return (
    <div id="homepage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="card-container dashBoard_cardContainer">
            <Link to="create-offer" style={{ color: blue[500], fontWeight: 100 }}>
              Make A New Offer/Coupon
            </Link>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>S.No.</TableCell>
                    <TableCell align="center">Coupon Code</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Change Status</TableCell>
                    <TableCell align="center">Delete</TableCell>
                    <TableCell align="center">Other Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allCoupons.map((coupon, index) => (
                    <TableRow key={coupon._id}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {coupon.couponCode}
                      </TableCell>
                      <TableCell align="center" style={{ width: 160 }}>
                        {coupon.isActive ? "Active" : "False"}
                      </TableCell>

                      <TableCell align="center" style={{ width: 160 }}>
                        <Tooltip title={coupon.isActive ? "Deactive Coupon" : "Activate Coupon"}>
                          <Link className="board_link">
                            <IconButton
                              size="small"
                              onClick={() => {
                                updateTheCoupon(coupon._id, coupon.isActive ? "false" : "true");
                              }}
                            >
                              {coupon.isActive ? (
                                <Close fontSize="inherit" color="error" />
                              ) : (
                                <Done fontSize="inherit" color="success" />
                              )}
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </TableCell>

                      <TableCell style={{ width: 160 }} align="center">
                        <Tooltip title="Delete Coupon">
                          <Link className="board_link">
                            <IconButton color="error" size="small">
                              <Delete fontSize="inherit" color="inherit" />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </TableCell>

                      <TableCell style={{ width: 160 }} align="center">
                        <Link to={coupon._id} className="board_link">
                          View Details
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Offer;
