import React, { useContext } from "react";
import AuthContext from "../../context/authContext";
import AreaGraph from "../../components/areaGraph/AreaGraph";
import DashBoardCard from "../../components/Cards/DashBoardCard";
import Navigation from "../../components/navigation/Navigation";
import Sidebar from "../../components/Sidebar/Sidebar";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";

import LineGraph from "../../components/Charts/LineChart";
import Footer from "../../components/Footer/Footer";

import "./Home.css";

const Home = () => {
  const AuthCntx = useContext(AuthContext);

  return (
    <div id="homepage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="card-container dashBoard_cardContainer">
            <DashBoardCard
              icon={<AccountBalanceWalletOutlinedIcon className="icon" />}
              amount={300}
              heading="Wallet Balance"
            />
            <DashBoardCard
              icon={<PersonOutlineOutlinedIcon className="icon" />}
              amount={"Saffron"}
              heading="Leadership Name"
            />
            <DashBoardCard icon={<ShareOutlinedIcon className="icon" />} amount={`₹ 200`} heading="Money Withdrawn" />
            <DashBoardCard icon={<AccountTreeOutlinedIcon className="icon" />} amount={5} heading="Referrals" />
          </div>

          <div className="graph-container">
            <AreaGraph title={"Income - last year"} />
            <LineGraph title={"Revenue - last year"} />
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Home;
