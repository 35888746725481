import * as React from "react";
import { Button, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import Navigation from "../../components/navigation/Navigation";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";
import CustomizedDialogs from "../../components/dialog/Dialog";
import BasicTable from "../../components/Tables/BasicTable";
import apiHelper from "../../utils/apiHelper";

const Categories = () => {
  const [categoryList, setCategoryList] = React.useState([]);
  const [categoryName, setCategoryName] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // fetch all categories
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await apiHelper("categories/get-all-category");
        setCategoryList(result.data);
      } catch (error) {
        // Handle error
      }
    };

    fetchData();
  }, []);

  const createNewCategory = async (e) => {
    try {
      e.preventDefault();
      const postData = {
        categoryName: categoryName,
      };
      const result = await apiHelper("categories/create-category", "POST", postData);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div id="homepage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="card-container dashBoard_cardContainer">
            <Button variant="outlined" onClick={handleClickOpen}>
              Add Category
            </Button>
            <BasicTable type="categories" width="100%" data={categoryList} />
          </div>
          <CustomizedDialogs
            open={open}
            dialogTitle="Add New Category"
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
          >
            <form action="" onSubmit={createNewCategory}>
              <Stack sx={{ my: 1 }}>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="cname"
                  name="cname"
                  label="Category Name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
              </Stack>
              <Stack direction={"row"} justifyContent={"flex-end"}>
                <Button type="submit">Submit</Button>
              </Stack>
            </form>
          </CustomizedDialogs>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Categories;
