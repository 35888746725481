import React from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navigation from "../../components/navigation/Navigation";
import ProfileComponent from "../../components/profile/Profile";

const Profile = () => {
  return (
    <div id="profilepage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="profile-container">
            <ProfileComponent type="user-view" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
