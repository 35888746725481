import React from "react";
import CopyrightIcon from "@mui/icons-material/Copyright";
import "./footer.css";

const Footer = () => {
  let date = new Date();
  return (
    <div className="footer">
      <span>
        <CopyrightIcon />
        {" " + date.getFullYear() + " "}
        Copyright
      </span>
      <span>Tranzift</span>
    </div>
  );
};

export default Footer;
