import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import apiHelper from "../../utils/apiHelper";

import Navigation from "../../components/navigation/Navigation";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";
import CategoryDetailsComp from "../../components/category/CategoryDetailsComp";

import "react-toastify/dist/ReactToastify.css";

const OfferDetails = () => {
  const [productsInTheCategory, setProductsInTheCategory] = useState([]);
  const [excludedProducts, setExcludedProducts] = useState([]);

  const [couponCode, setCouponCode] = useState("");

  const id = useParams();

  const fetchData = async () => {
    try {
      const result = await apiHelper(`coupon/${id.id}`);
      console.log(result);
      setCouponCode(result.coupon.couponCode);
      setExcludedProducts(result.excludedProducts);
      setProductsInTheCategory(result.includedProducts);
    } catch (error) {
      // Handle error
    }
  };

  const addProductToCoupon = async (productSku) => {
    try {
      await apiHelper(`coupon/addgcincoupon/${id.id}`, "PATCH", {
        products: productSku,
      });
      fetchData();
    } catch (error) {
      // Handle error
    }
  };

  const removeProductFromCoupon = async (productSku) => {
    try {
      const res = await apiHelper(`coupon/removegcfromcoupon/${id.id}`, "PATCH", {
        products: productSku,
      });

      console.log(res);
      fetchData();
    } catch (error) {
      // Handle error
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="homepage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="card-container dashBoard_cardContainer">{couponCode}</div>

          <div className="category_giftCard_container">
            <CategoryDetailsComp
              type="remove"
              heading="Products in the Coupon"
              data={productsInTheCategory}
              callType="coupon"
              removeProductFromCategory={removeProductFromCoupon}
            />
            <CategoryDetailsComp
              type="add"
              heading="Excluded products"
              data={excludedProducts}
              callType="coupon"
              addProductToCategory={addProductToCoupon}
            />
          </div>
          <ToastContainer />

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default OfferDetails;
