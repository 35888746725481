import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import apiHelper from "../../utils/apiHelper";

import Navigation from "../../components/navigation/Navigation";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";
import CustomizedDialogs from "../../components/dialog/Dialog";

import { ArrowBack } from "@mui/icons-material";

import "react-toastify/dist/ReactToastify.css";
import "./gcdetails.css";

function MetaDialog({ open, handleClose, id }) {
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");

  async function updateMeta(e) {
    try {
      e.preventDefault();
      const res = await apiHelper(`giftcards/update-product/${id}`, "patch", {
        metaTitle,
        metaDescription,
        metaKeywords,
      });

      console.log(res);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <CustomizedDialogs open={open} handleClose={handleClose} dialogTitle="Edit Meta Tags">
        <form action="" onSubmit={updateMeta}>
          <div className="input-form_container">
            <div className="input-form">
              <label htmlFor="meta-title">Meta Title*</label>
              <input
                type="text"
                id="meta-title"
                name="meta-title"
                placeholder="Enter The Meta Title Here"
                value={metaTitle}
                required
                onChange={(e) => setMetaTitle(e.target.value)}
              />
            </div>
          </div>

          <div className="input-form_container">
            <div className="input-form">
              <label htmlFor="meta-desc">Meta Description*</label>
              <input
                type="text"
                id="meta-desc"
                name="meta-desc"
                value={metaDescription}
                placeholder="Enter The Meta Description Here"
                required
                onChange={(e) => setMetaDescription(e.target.value)}
              />
            </div>
          </div>

          <div className="input-form">
            <label htmlFor="meta-key">Meta Keywords*</label>
            <input
              type="text"
              id="meta-key"
              name="meta-key"
              placeholder="Enter The Meta Keywords Here"
              value={metaKeywords}
              required
              onChange={(e) => setMetaKeywords(e.target.value)}
            />
          </div>

          <div className="input-form">
            <Button color="error" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" color="success">
              Submit
            </Button>
          </div>
        </form>
      </CustomizedDialogs>
    </>
  );
}

const GiftCardDetails = () => {
  const [webImg, setWebImg] = useState(null);
  const [mobImg, setMobImg] = useState(null);

  const [webImgPreview, setWebImgPreview] = useState(null);
  const [mobImgPreview, setMobImgPreview] = useState(null);
  const [gcId, setGCID] = useState("");
  const [productName, setProductName] = useState("");
  const [sku, setSku] = useState("");
  const [status, setStatus] = useState(true);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const id = useParams();

  const fetchData = async () => {
    try {
      const result = await apiHelper(`giftcards/gcdetails/${id.id}`);
      setGCID(result.GCDetails._id);
      setProductName(result.GCDetails.nameOnWoohoo);
      setSku(result.GCDetails.sku);
      setMobImgPreview(result.GCDetails.productImgMob); //preview
      setWebImgPreview(result.GCDetails.productImgWeb); //preview
      setStatus(result.GCDetails.isActive);
    } catch (error) {
      // Handle error
    }
  };

  const setWebImgHandler = (e) => {
    setWebImg(e.target.files[0]);

    setWebImgPreview(URL.createObjectURL(e.target.files[0])); //preview
  };

  const setMobImgHandler = (e) => {
    setMobImg(e.target.files[0]);

    setMobImgPreview(URL.createObjectURL(e.target.files[0])); //preview
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const gcImages = new FormData();
      gcImages.append("sku", sku);

      webImg ? gcImages.append("webImg", webImg) : gcImages.append("webImg", webImgPreview);

      mobImg ? gcImages.append("mobImg", mobImg) : gcImages.append("mobImg", mobImgPreview);

      const data = await apiHelper(`giftcards/upload-gc-img/${gcId}`, "PATCH", gcImages);

      if (data.status.toLowerCase() === "success") {
        toast.success("Gift Card Updated Sucessfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="homepage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="card-container dashBoard_cardContainer">
            <Button variant="outlined" color="error" startIcon={<ArrowBack />}>
              Go Back
            </Button>
            {productName} - {sku}
          </div>

          <div className="gcDetailsContainer">
            <div className="left-side">
              <div className="openDialogButton">
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  Edit Meta
                </Button>
              </div>
              <form action="" encType="multipart/form-data" onSubmit={formSubmitHandler}>
                <div className="input-form_container">
                  <div className="input-form">
                    <label htmlFor="cname">GC Name :</label>
                    <input type="text" id="cname" value={productName} name="cname" disabled />
                  </div>
                </div>

                <div className="input-form_flex-container">
                  <div className="input-form">
                    <label htmlFor="cstatus">GC Status :</label>
                    <select name="status" id="status" disabled>
                      {status && <option value="true">Active</option>}
                      {!status && <option value="false">Not Active</option>}
                    </select>
                  </div>

                  <div className="input-form">
                    <label htmlFor="gcSKU">SKU :</label>
                    <input type="text" id="gcSKU" value={sku} name="gcSKU" disabled />
                  </div>
                </div>

                <div className="input-form_flex-container">
                  <div className="input-form">
                    <label htmlFor="gcimgweb">Web GC Image :</label>
                    <input type="file" id="gcimgweb" name="gcimgweb" onChange={(e) => setWebImgHandler(e)} />
                  </div>

                  <div className="input-form">
                    <label htmlFor="gccimgmob">Mob GC Image :</label>
                    <input type="file" id="gccimgmob" name="gccimgmob" onChange={(e) => setMobImgHandler(e)} />
                  </div>
                </div>

                <div className="preview-img-section">
                  <div className="preview_img_container">
                    <img
                      src={
                        webImgPreview
                          ? webImgPreview
                          : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                      }
                      alt="preview-img"
                    />
                  </div>

                  <div className="preview_img_container">
                    <img
                      src={
                        mobImgPreview
                          ? mobImgPreview
                          : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                      }
                      alt="preview-img"
                    />
                  </div>
                </div>

                <div className="input-form_container">
                  <div className="input-form">
                    <Button type="submit" sx={{ my: 2 }} variant="outlined">
                      Save Product
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <MetaDialog open={open} handleClose={handleClose} id={gcId} />
          <ToastContainer />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default GiftCardDetails;
