import { useState } from "react";
import { Link } from "react-router-dom";

import { MdOutlineSpaceDashboard, MdOutlineContactSupport } from "react-icons/md";
import { CiGift, CiShoppingCart } from "react-icons/ci";
import { VscSymbolVariable } from "react-icons/vsc";
import { RiCoupon3Line } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

import siteLogo from "../../logo.png";
import "./sidebar.css";

const Sidebar = () => {
  const [isTreeOptionVisible, setIsTreeOptionVisible] = useState(false);

  const handleTreeOption = () => {
    isTreeOptionVisible ? setIsTreeOptionVisible(false) : setIsTreeOptionVisible(true);
  };
  return (
    <div className="sidebar">
      <div className="top">
        <img src={siteLogo} alt="site-logo" />
      </div>
      <div className="bottom">
        <ul>
          <li>
            <Link to="/">
              <MdOutlineSpaceDashboard /> Dashboard
            </Link>
          </li>

          <li onClick={handleTreeOption} className="treeContainer">
            <Link>
              <CiGift />
              Gift Cards
            </Link>
            {isTreeOptionVisible ? <BiChevronUp /> : <BiChevronDown />}
          </li>

          {isTreeOptionVisible && (
            <>
              <li>
                <Link to="/categories">
                  <VscSymbolVariable /> Categories
                </Link>
              </li>
              <li>
                <Link to="/giftcards">
                  <CiShoppingCart /> Products
                </Link>
              </li>

              <li>
                <Link to="/offer">
                  <RiCoupon3Line /> coupon
                </Link>
              </li>
            </>
          )}

          <li>
            <Link to="/allusers">
              <FiUsers />
              All users
            </Link>
          </li>

          <li>
            <Link to="/tickets">
              <MdOutlineContactSupport /> Support
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
