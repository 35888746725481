import React, { useState, useEffect } from "react";
import axios from "axios";
import download from "downloadjs";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/Footer/Footer";
import { Button } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "react-toastify/dist/ReactToastify.css";
import { RxCross1 } from "react-icons/rx";

import "./kycRequestDetails.css";

const KycDetails = () => {
  const params = useParams();
  const history = useNavigate();
  const [kycRequestsDetails, setKycRequestsDetails] = useState([]);

  //
  const [dialogTitle, setDialogTitle] = useState("Default Dialog");
  const [dialogImg, setDialogImg] = useState("");
  const [zoom, setZoom] = useState(false);

  const fetchKycRequestDetails = async () => {
    try {
      const response = await axios.get(`https://api.tranzift.com/api/v1/kyc/${params.id}`);

      setKycRequestsDetails(response.data.kycRequest);
    } catch (err) {
      console.log(err);
    }
  };

  const acceptTheRequest = async () => {
    try {
      const res = await axios({
        method: "PATCH",
        url: `https://api.tranzift.com/api/v1/kyc/${params.id}`,
        data: {
          userid: localStorage.getItem("id"),
          isAccepted: true,
          isNewRequest: false,
          Status: "accepted",
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.status === 200) {
        toast.success("Request Accepted !! ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setTimeout(() => {
          history("/kycrequests");
        }, 2300);
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const rejectTheRequest = async () => {
    try {
      const res = await axios({
        method: "PATCH",
        url: `https://api.tranzift.com/api/v1/kyc/${params.id}`,
        data: {
          userid: localStorage.getItem("id"),
          isCancelled: true,
          isNewRequest: false,
          Status: "rejected",
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        toast.success("Request Rejected !! ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setTimeout(() => {
          history("/kycrequests");
        }, 2300);
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const zoomIdHandler = (imgUrl) => {
    setDialogTitle("User Id Proof");
    setZoom(true);
    setDialogImg(imgUrl);
  };

  const zoomPanHandler = (imgUrl) => {
    setDialogTitle("User Pan Card");
    setZoom(true);
    setDialogImg(imgUrl);
  };

  const zoomBankHandler = (imgUrl) => {
    setDialogTitle("User Bank Proof");
    setZoom(true);
    setDialogImg(imgUrl);
  };

  const handleClose = () => {
    setZoom(false);
  };

  const handleDownloadClick = (imageURL) => {
    // Create a hidden anchor element to trigger the download
    // const downloadLink = document.createElement("a");
    // downloadLink.href = imageURL;
    // downloadLink.download = "image.png"; // You can set the desired file name
    // document.body.appendChild(downloadLink);
    // downloadLink.click();

    download(imageURL, `${imageURL.split("/")[5]}`, "image/png");
    // document.body.removeChild(downloadLink);
  };

  // const setImgToDowlload = (url) => {
  //   handleDownloadClick(url);
  // };

  useEffect(() => {
    fetchKycRequestDetails();
  }, []);

  return (
    <div id="boardpage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="kyc-request-container">
            <div className="kyc-details_left">
              <div className="kyc_img_container">
                <h4 className="img_heading">Id Proof</h4>
                <div className="act-img">
                  <img src={kycRequestsDetails.idFile} alt="" />
                </div>
                <div className="view_container" style={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    size="small"
                    variant="outlined"
                    endIcon={<FileDownloadOutlinedIcon />}
                    onClick={() => {
                      handleDownloadClick(kycRequestsDetails.idFile);
                    }}
                  >
                    Download
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    endIcon={<RemoveRedEyeOutlinedIcon />}
                    onClick={() => zoomIdHandler(kycRequestsDetails.idFile)}
                  >
                    View
                  </Button>
                </div>
              </div>
              <div className="kyc_img_container">
                <h4 className="img_heading">Pan Card</h4>
                <div className="act-img">
                  <img src={kycRequestsDetails.panFile} alt="" />
                </div>
                <div className="view_container" style={{ display: "flex", justifyContent: "space-between" }}>
                  <Button size="small" variant="outlined" endIcon={<FileDownloadOutlinedIcon />}>
                    Download
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    endIcon={<RemoveRedEyeOutlinedIcon />}
                    onClick={() => zoomPanHandler(kycRequestsDetails.panFile)}
                  >
                    View
                  </Button>
                </div>
              </div>
              <div className="kyc_img_container">
                <h4 className="img_heading">Bank Document</h4>
                <div className="act-img">
                  <img src={kycRequestsDetails.bankDetailsFile} alt="" />
                </div>
                <div className="view_container" style={{ display: "flex", justifyContent: "space-between" }}>
                  <Button size="small" variant="outlined" endIcon={<FileDownloadOutlinedIcon />}>
                    Download
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    endIcon={<RemoveRedEyeOutlinedIcon />}
                    onClick={() => zoomBankHandler(kycRequestsDetails.bankDetailsFile)}
                  >
                    View
                  </Button>
                </div>
              </div>
            </div>
            <Dialog open={zoom} onClose={handleClose}>
              <DialogTitle className="Registration_title">
                <span>{dialogTitle}</span>
                <RxCross1 style={{ cursor: "pointer" }} onClick={handleClose} />
              </DialogTitle>
              <DialogContent>
                <img src={dialogImg} alt="" className="dialog_img" />
                {/* <Button onClick={handleClose} color="error" style={{ marginRight: "10px" }}>
                  Close
                </Button> */}
              </DialogContent>
            </Dialog>
            <div className="kyc-details_right">
              <table>
                <tbody>
                  <tr>
                    <th>Company</th>
                    <th>Contact</th>
                  </tr>
                  <tr>
                    <td>Requested By</td>
                    <td>{kycRequestsDetails.requestedBy}</td>
                  </tr>
                  <tr>
                    <td>Id Number</td>
                    <td>{kycRequestsDetails.idNumber}</td>
                  </tr>
                  <tr>
                    <td>Pan Number</td>
                    <td>{kycRequestsDetails.panNumber}</td>
                  </tr>
                  <tr>
                    <td>Bank Account Holder Name</td>
                    <td>{kycRequestsDetails.accountHolderName}</td>
                  </tr>
                  <tr>
                    <td>Bank Account Number </td>
                    <td>{kycRequestsDetails.bankAccountNumber}</td>
                  </tr>
                  <tr>
                    <td>Bank IFSC</td>
                    <td>{kycRequestsDetails.bankIfsc}</td>
                  </tr>
                  <tr>
                    <td>Bank Branch</td>
                    <td>{kycRequestsDetails.bankBrachName}</td>
                  </tr>
                </tbody>
              </table>
              {!kycRequestsDetails.isCancelled && !kycRequestsDetails.isAccepted && (
                <div className="epin-button-container">
                  <Button color="success" variant="outlined" onClick={acceptTheRequest}>
                    Accept
                  </Button>

                  <Button color="error" variant="outlined" onClick={rejectTheRequest}>
                    Reject
                  </Button>
                </div>
              )}
            </div>
          </div>
          <ToastContainer />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default KycDetails;
