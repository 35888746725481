import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import AuthContext from "../../context/authContext";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./LoginForm.module.css";
import apiHelper from "../../utils/apiHelper";

const LoginForm = (props) => {
  const history = useNavigate();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");

  const authCntxt = useContext(AuthContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    try {
      const data = await apiHelper(`authenticate/login`, "post", {
        email,
        password,
        isAdminLogin: true,
      });

      if (data.status === "success") {
        authCntxt.login(data.token, data.user.role, data.user._id, data.user);
      }
      history("/");
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const forgetPasswordHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("https://api.tranzift.com/api/v1/auth/forgotpassword", {
        userName: userName,
      });

      setOpen(false);

      if (res.status === 200) {
        toast.success("Password rest link sent to your email", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <form action="" className="addUser" onSubmit={loginHandler}>
        <div className={`form-part ${styles.form_part_module}`}>
          <span className="part-heading">{props.heading}</span>
          <div className={`input-group ${styles.input_group_module}`}>
            <label htmlFor="email">
              Email <span>*</span>
            </label>
            <input
              type="text"
              placeholder="Enter Your Email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className={`input-group ${styles.input_group_module}`}>
            <label htmlFor="password">
              Password <span>*</span>
            </label>
            <input
              type="password"
              placeholder="Enter Your password"
              name="password"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className={`flex-input-group ${styles.input_group_module}`}>
            <Link style={{ color: "#f35e63", fontWeight: "100" }} onClick={handleClickOpen}>
              Forgot Password ?
            </Link>
          </div>

          <div className="input-group">
            <Button type="submit" variant="outlined" color="secondary">
              Login
            </Button>
          </div>
        </div>
      </form>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Forgot Password ? Don't Worry</DialogTitle>

        <DialogContent>
          <form action="" onSubmit={forgetPasswordHandler}>
            <div className="dialog-form-part">
              <div className="input-group">
                <label htmlFor="name">
                  Username <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Username to find your account"
                  name="name"
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="input-group">
                <Button onClick={handleClose} color="error" style={{ marginRight: "10px" }}>
                  Cancel
                </Button>
                <Button type="submit" color="secondary">
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <ToastContainer />
    </>
  );
};

export default LoginForm;
