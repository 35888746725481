import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import siteLogo from "../../logo.png";
import mobSiteLogo from "../../mobile-logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../../context/authContext";
import { ImTree } from "react-icons/im";

import "./navigation.css";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { FcGenealogy } from "react-icons/fc";
import { GrDirections } from "react-icons/gr";
import apiHelper from "../../utils/apiHelper";

const Navigation = (props) => {
  const AuthCntx = useContext(AuthContext);

  const [selected, setSelected] = useState(false);
  const [isTreeOptionVisible, setIsTreeOptionVisible] = useState(false);
  const handleTreeOption = () => {
    isTreeOptionVisible ? setIsTreeOptionVisible(false) : setIsTreeOptionVisible(true);
  };
  const clickHandler = () => {
    selected ? setSelected(false) : setSelected(true);
  };

  const woohoAuthHandler = async () => {
    try {
      const authResponse = await apiHelper(`woohoo/authenticate`, "POST");

      if (authResponse.status === 200) {
        toast.success(authResponse.message, {
          autoClose: 3000,
          theme: "dark",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchWoohooProducts = async () => {
    try {
      const woohooproduct = await apiHelper(`woohooproduct/fetchproductsfromwoohoo`);

      if (woohooproduct.status === "success") {
        toast.success("Fetched Products from woohoo", {
          autoClose: 3000,
          theme: "dark",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validateProducts = async () => {
    try {
      const validateRes = await apiHelper(`woohooproduct/validatewoohooproducts`);
      if (validateRes.status === "success") {
        toast.success(validateRes.message, {
          autoClose: 3000,
          theme: "dark",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="nav" id="nav">
        <div className="nav-wrapper">
          <div className="left-side">
            <Link to="/">
              <div className="logo">
                <img src={mobSiteLogo} alt="site-logo" />
              </div>
            </Link>
            <div className="__edit">
              <Link to="/">
                <Tooltip title="Edit Profile" arrow>
                  <Button>
                    <EditIcon />
                  </Button>
                </Tooltip>
              </Link>
              <div onClick={() => clickHandler(!selected)}>{selected ? <CloseIcon /> : <MenuIcon />}</div>
            </div>
          </div>
          <div className="right-side">
            <div className="account-name">
              {/* <div className="profileImgContainer">
                <img src={defaultProfileImg} alt="profile-img" />
              </div>
              <span style={{ color: "#1976d2" }}>akas21901</span> */}

              <Tooltip title="Authenticate with Woohoo" arrow>
                <Button color="error" variant="outlined" onClick={woohoAuthHandler}>
                  1 Woohoo Auth
                </Button>
              </Tooltip>

              <Button color="success" variant="outlined" onClick={fetchWoohooProducts}>
                2 Fetch Woohoo Products
              </Button>

              <Button color="warning" variant="outlined" onClick={validateProducts}>
                3 Validate With LocalDB
              </Button>

              <Button variant="contained" color="secondary" onClick={AuthCntx.logout}>
                Logout
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className={selected ? "mobile-nav show" : "mobile-nav none"}>
        <div className="top">
          <img src={siteLogo} alt="site-logo" />
        </div>
        <div className="bottom">
          <ul>
            {/* Both */}
            <li>
              <Link to="/">
                <DashboardIcon /> Dashboard
              </Link>
            </li>

            <li onClick={handleTreeOption} className="treeContainer">
              <Link>
                <ImTree />
                Gift Cards
              </Link>
              {isTreeOptionVisible ? <BiChevronUp /> : <BiChevronDown />}
            </li>

            {isTreeOptionVisible && (
              <>
                <li>
                  <Link to="/categories">
                    <FcGenealogy /> Categories
                  </Link>
                </li>
                <li>
                  <Link to="/giftcards">
                    <GrDirections /> Products
                  </Link>
                </li>

                <li>
                  <Link to="/offer">
                    <GrDirections /> Coupons
                  </Link>
                </li>
              </>
            )}

            <li>
              <Link to="/allusers">
                <SimCardDownloadOutlinedIcon />
                All users
              </Link>
            </li>

            <li>
              <Link to="/tickets">
                <AccountTreeOutlinedIcon /> Support
              </Link>
            </li>

            <div
              className="logoutBtn"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                bottom: 0,
                borderTop: "2px lightgray solid",
              }}
            >
              <Button color="secondary" onClick={AuthCntx.logout}>
                Logout
              </Button>
            </div>
          </ul>
        </div>
      </div>

      <div
        className={selected ? "sidebar-overlay show" : "sidebar-overlay none"}
        onClick={() => clickHandler(false)}
      ></div>

      <ToastContainer />
    </>
  );
};

export default Navigation;
