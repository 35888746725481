import React, { useEffect, useState } from "react";
import Navigation from "../../components/navigation/Navigation";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Link } from "react-router-dom";
import apiHelper from "../../utils/apiHelper";
import { Button } from "@mui/material";

const GiftCard = () => {
  const [giftCards, setGiftCards] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchAllGC = async () => {
    try {
      const gcResponse = await apiHelper(`giftcards/allCards?page=${page}&limit=${rowsPerPage}`);
      setTotalPages(Math.ceil(gcResponse.size / rowsPerPage));

      if (gcResponse.status === "success") {
        setGiftCards(gcResponse.giftCards);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const prevPageHandler = () => {
    setPage((prevpage) => prevpage - 1);
  };

  const nextPageHandler = () => {
    setPage((prevpage) => prevpage + 1);
  };

  const perPageItemHandler = (event) => {
    setRowsPerPage(event.target.value);
  };

  const findProduct = async (e) => {
    try {
      setSearchQuery(e.target.value);
      const gcResponse = await apiHelper(`giftcards/allCards?search=${searchQuery}`);

      if (gcResponse.status === "success" && searchQuery.length > 0) {
        setGiftCards(gcResponse.giftCards);
        setPage(1);
        setTotalPages(0);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllGC();
  }, [page, rowsPerPage]);

  return (
    <div id="homepage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="card-container dashBoard_cardContainer">
            <label htmlFor="search">
              <input type="text" id="search" name="search" onChange={(e) => findProduct(e)} />
            </label>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>S.No.</TableCell>
                    <TableCell align="center">Gift Card Name</TableCell>
                    <TableCell align="center">SKU</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {giftCards.map((giftCard, index) => (
                    <TableRow key={giftCard._id}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {giftCard.nameOnWoohoo}
                      </TableCell>
                      <TableCell align="center" style={{ width: 160 }}>
                        {giftCard.sku}
                      </TableCell>

                      <TableCell style={{ width: 160 }} align="center">
                        <Link to={`${giftCard.sku}`} className="board_link">
                          View Details
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <select
              name="pageoptions"
              id="pageoptions"
              onChange={(e) => {
                perPageItemHandler(e);
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
            </select>
            <Button onClick={prevPageHandler} disabled={page === 1 ? true : false}>
              Prev Page
            </Button>
            <Button onClick={nextPageHandler} disabled={page >= totalPages ? true : false}>
              Next Page
            </Button>
            <label>Jump To Page:</label>
            <select
              onChange={(e) => {
                setPage(e.target.value * 1);
              }}
            >
              {[...Array(totalPages).keys()].map((index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
            currentPage:{page}
            totalPages:{totalPages}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default GiftCard;
