import { useEffect, useState } from "react";
import Navigation from "../../components/navigation/Navigation";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";
import styles from "./createoffer.module.css";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import apiHelper from "../../utils/apiHelper";
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

const CreateOffer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [errMsg, setErrMsg] = useState("Something went wrong");
  const [isSuccess, setIsSuccess] = useState(true);

  const [couponCode, setCouponCode] = useState("");
  const [minOrderPrice, setMinOrderPrice] = useState(1000);
  const [maxOrderPrice, setMaxOrderPrice] = useState(10000);
  const [minOrderQty, setMinOrderQty] = useState(1);
  const [applicableTime, setApplicableTime] = useState(1);
  const [applicablePerDay, setApplicablePerDay] = useState(30);
  const [applicableDays, setApplicableDays] = useState(["mon", "tue"]);
  const [applicableMedium, setApplicableMedium] = useState("both");

  const [disocuntApplicationType, setDiscountApplicationType] = useState("instant");
  const [discountType, setDiscountType] = useState("flat");
  const [discountAmountType, setDiscountAmountType] = useState("percentage");
  const [discountAmount, setDiscountAmount] = useState("8");
  const [maxDiscountAmount, setMaxDiscountAmount] = useState("");

  const [UsageLimit, setUsageLimit] = useState("1000");
  const [isUserVerificationRequired, setIsUserVerificationRequired] = useState(true);
  const [isApplicableToAllProducts, setIsApplicableToAllProducts] = useState(false);
  const [isVisibleToAllUsers, setIsVisibleToAllUsers] = useState(false);
  const [StartsFrom, setStartsFrom] = useState("");
  const [endAt, setEndAt] = useState("");

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { value } = event.target;
    setApplicableDays(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setShowSpinner(true);

      const offerData = {
        couponCode,
        minOrderPrice,
        maxOrderPrice,
        minOrderQty,
        applicableDays,
        applicableMedium,
        applicablePerDay,
        applicableTime,
        disocuntApplicationType,
        discountType,
        discountAmountType,
        discountAmount,
        maxDiscountAmount,
        UsageLimit,
        isApplicableToAllProducts,
        isUserVerificationRequired,
        isVisibleToAllUsers,
        StartsFrom,
        endAt,
      };

      const res = await apiHelper("coupon/create-coupon", "post", offerData);

      if (res.status === "success") {
        setShowSpinner(false);
        setIsSuccess(true);
      }
    } catch (err) {
      setShowSpinner(false);
      setIsSuccess(false);
      setIsError(true);
      setErrMsg(err.response.data.message);
    }
  };

  const handleBack = () => {
    setIsLoading(false);
    setIsError(false);
    setIsSuccess(false);
  };

  const createNewCoupon = () => {
    setCouponCode("");
    setMinOrderPrice(1000);
    setMaxOrderPrice(10000);
    setMinOrderQty(1);
    setApplicableDays(["mon", "tue"]);
    setApplicableMedium("both");
    setApplicablePerDay(30);
    setApplicableTime(1);
    setDiscountApplicationType("instant");
    setDiscountType("flat");
    setDiscountAmountType("percentage");
    setDiscountAmount("8");
    setMaxDiscountAmount("");
    setUsageLimit("1000");
    setIsApplicableToAllProducts(false);
    setIsUserVerificationRequired(true);
    setIsVisibleToAllUsers(false);
    setStartsFrom("");
    setEndAt("");

    setIsLoading(false);
    setIsError(false);
    setIsSuccess(false);
  };

  return (
    <div id="homepage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="card-container dashBoard_cardContainer">
            <Button startIcon={<ArrowBack />} color="error" variant="outlined" onClick={() => navigate(-1)}>
              Go Back
            </Button>
            <div className={styles.form_container}>
              {isLoading && (
                <div className={styles.loading_state}>
                  {showSpinner && <CircularProgress />}
                  {isSuccess && (
                    <>
                      <p>Coupon Created Successfully</p>
                      <Button variant="contained" color="success" onClick={createNewCoupon}>
                        Create New Coupon
                      </Button>
                    </>
                  )}
                  {isError && (
                    <>
                      <p>{errMsg}</p>
                      <Button variant="contained" color="error" startIcon={<ArrowBack />} onClick={handleBack}>
                        Go Back
                      </Button>
                    </>
                  )}
                </div>
              )}
              <form onSubmit={HandleSubmit}>
                <div className="input-group">
                  <label htmlFor="coupon-code">Coupon Code</label>
                  <input
                    type="text"
                    id="coupon-code"
                    name="coupon-code"
                    placeholder="Type Coupon Code"
                    value={couponCode}
                    onChange={(e) => {
                      setCouponCode(e.target.value);
                    }}
                  />
                </div>

                <div className={styles.flex_input}>
                  <div className="input-group">
                    <label htmlFor="min-order-price">Min. Order Price</label>
                    <input
                      type="text"
                      id="min-order-price"
                      name="min-order-price"
                      placeholder="Type the minimum order price"
                      value={minOrderPrice}
                      onChange={(e) => {
                        setMinOrderPrice(e.target.value);
                      }}
                    />
                  </div>

                  <div className="input-group">
                    <label htmlFor="max-order-price">Max. Order Price</label>
                    <input
                      type="text"
                      id="max-order-price"
                      name="max-order-price"
                      placeholder="Type the maximum order price"
                      value={maxOrderPrice}
                      onChange={(e) => setMaxOrderPrice(e.target.value)}
                    />
                  </div>
                </div>

                <div className={styles.flex_input_three}>
                  <div className="input-group">
                    <label htmlFor="min-order-qty">Min. Order Qty</label>
                    <input
                      type="text"
                      id="min-order-qty"
                      name="min-order-qty"
                      placeholder="Type minimum order qty required"
                      value={minOrderQty}
                      onChange={(e) => setMinOrderQty(e.target.value)}
                    />
                  </div>

                  <div className="input-group">
                    <label htmlFor="applicable-time">Applicable Time</label>
                    <input
                      type="text"
                      id="applicable-time"
                      name="applicable-time"
                      value={applicableTime}
                      placeholder="Type applicable times of the coupon"
                      onChange={(e) => setApplicableTime(e.target.value)}
                    />
                  </div>

                  <div className="input-group">
                    <label htmlFor="applicable-per-day">Applicable Per Day</label>
                    <input
                      type="text"
                      id="applicable-per-day"
                      name="applicable-per-day"
                      placeholder="Type Applicable per day"
                      value={applicablePerDay}
                      onChange={(e) => setApplicablePerDay(e.target.value)}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="applicable-days">Applicable Days</InputLabel>
                    <Select
                      labelId="applicable-days"
                      id="applicable-days"
                      multiple
                      value={applicableDays}
                      onChange={handleChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(",")}
                      MenuProps={MenuProps}
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={applicableDays.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className={styles.flex_input}>
                  <div className="input-group">
                    <label htmlFor="discount-application-type">Discount Application Type</label>
                    <select
                      name="discount-application-type"
                      value={disocuntApplicationType}
                      onChange={(e) => setDiscountApplicationType(e.target.value)}
                    >
                      <option value="instant">instant</option>
                      <option value="cashback">cashback</option>
                    </select>
                  </div>

                  <div className="input-group">
                    <label htmlFor="discount-type">Discount Type</label>
                    <select name="discount-type" value={discountType} onChange={(e) => setDiscountType(e.target.value)}>
                      <option value="flat">Flat</option>
                      <option value="upto">Upto</option>
                    </select>
                  </div>
                </div>

                <div className={styles.flex_input_three}>
                  <div className="input-group">
                    <label htmlFor="amount-type">Discount Amount Type</label>
                    <select
                      name="amount-type"
                      value={discountAmountType}
                      onChange={(e) => setDiscountAmountType(e.target.value)}
                    >
                      <option value="percentage">Percentage</option>
                      <option value="fixed">Fixed</option>
                    </select>
                  </div>

                  <div className="input-group">
                    <label htmlFor="discount-amount">Discount Amount</label>
                    <input
                      type="text"
                      id="discount-amout"
                      name="discount-amount"
                      placeholder="Type the discount amount"
                      value={discountAmount}
                      onChange={(e) => setDiscountAmount(e.target.value)}
                    />
                  </div>

                  <div className="input-group">
                    <label htmlFor="max-discount-amount">Max Discount Amount</label>
                    <input
                      type="text"
                      id="max-discount-amout"
                      name="max-discount-amount"
                      placeholder="Type the max discount amount"
                      value={maxDiscountAmount}
                      onChange={(e) => setMaxDiscountAmount(e.target.value)}
                    />
                  </div>
                </div>

                <div className={styles.flex_input_three}>
                  <div className="input-group">
                    <label htmlFor="applicable-medium">Applicable Medium</label>
                    <select
                      name="applicable-medium"
                      value={applicableMedium}
                      onChange={(e) => setApplicableMedium(e.target.value)}
                    >
                      <option value="both">Both</option>
                      <option value="website">Website</option>
                      <option value="mobile-app">Mobile-app</option>
                    </select>
                  </div>

                  <div className="input-group">
                    <label htmlFor="usage-limit">Usage Limit</label>
                    <input
                      name="usage-limit"
                      type="text"
                      placeholder="Enter Usage Limit"
                      value={UsageLimit}
                      onChange={(e) => setUsageLimit(e.target.value)}
                    />
                  </div>

                  <div className="input-group">
                    <label htmlFor="user-verification">User verification </label>
                    <select
                      name="user-verification"
                      value={isUserVerificationRequired}
                      onChange={(e) => setIsUserVerificationRequired(e.target.value)}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                </div>

                <div className={styles.flex_input}>
                  <div className="input-group">
                    <label htmlFor="applicable-all">Applicable to all</label>
                    <select
                      name="applicable-all"
                      value={isApplicableToAllProducts}
                      onChange={(e) => {
                        setIsApplicableToAllProducts(e.target.value);
                      }}
                    >
                      <option value="true">Yes</option>
                      <option value="false">False</option>
                    </select>
                  </div>

                  <div className="input-group">
                    <label htmlFor="all-visible">Visible to all</label>
                    <select
                      name="all-visible"
                      value={isVisibleToAllUsers}
                      onChange={(e) => {
                        setIsVisibleToAllUsers(e.target.value);
                      }}
                    >
                      <option value="true">Yes</option>
                      <option value="false">False</option>
                    </select>
                  </div>
                </div>

                <div className={styles.flex_input}>
                  <div className="input-group">
                    <label htmlFor="offer-start-date">Offer Starts From</label>
                    <input
                      type="date"
                      name="offer-start-date"
                      id="offer-start-date"
                      value={StartsFrom}
                      onChange={(e) => setStartsFrom(e.target.value)}
                    />
                  </div>

                  <div className="input-group">
                    <label htmlFor="offer-end-date">Offer Ends At</label>
                    <input
                      type="date"
                      name="offer-end-date"
                      id="offer-end-date"
                      value={endAt}
                      onChange={(e) => {
                        setEndAt(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="input-group">
                  <Button type="submit" variant="outlined">
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default CreateOffer;
