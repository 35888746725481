import { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import KycDetails from "./components/KYC/KycDetails";

import Home from "./pages/Home/Home";
import Offer from "./pages/offer/Offer";
import Login from "./pages/login/Login";
import Profile from "./pages/Profile/Profile";
import AllUser from "./pages/AllUserPage/AllUser";
import GiftCard from "./pages/GiftCardPage/GiftCard";
import NotFound from "./pages/NotFound/NotFound";
import Categories from "./pages/categories/Categories";
import CreateOffer from "./pages/offer/CreateOffer";
import OfferDetails from "./pages/offer/OfferDetails";
import UserDetails from "./pages/AllUserPage/UserDetails";
import TicketDetails from "./pages/supportTicket/TicketDetails";
import SupportTicket from "./pages/supportTicket/SupportTicket";
import AllKycRequests from "./pages/KycRequests/AllKycRequests";
import GiftCardDetails from "./pages/GiftCardPage/GiftCardDetails";
import CategoryDetails from "./pages/categories/CategoryDetails";
import { AddCategoryToProduct } from "./pages/GiftCardPage/AddCategoryToProduct";
import "./App.css";

import AuthContext from "./context/authContext";

function App() {
  const AuthCntxt = useContext(AuthContext);

  return (
    <Router>
      <Routes>
        {AuthCntxt.isLoggedIn ? <Route path="/" element={<Home />} /> : <Route path="/" element={<Login />} />}

        <Route path="profile" element={<Profile />} />

        <Route path="tickets" element={<SupportTicket />} />

        <Route path="tickets">
          <Route path=":id" element={<TicketDetails />} />
        </Route>

        <Route path="allusers">
          <Route index element={<AllUser />} />
          <Route path="user/:id" element={<UserDetails />} />
        </Route>

        <Route path="kycrequests">
          <Route index element={<AllKycRequests />} />
          <Route path=":id" element={<KycDetails />} />
        </Route>

        <Route path="categories">
          <Route index element={<Categories />} />
          <Route path=":id" element={<CategoryDetails />} />
        </Route>

        <Route path="offer">
          <Route index element={<Offer />} />
          <Route path=":id" element={<OfferDetails />} />
          <Route path="create-offer" element={<CreateOffer />} />
        </Route>

        <Route path="giftcards">
          <Route index element={<GiftCard />} />
          <Route path=":id" element={<GiftCardDetails />} />
          <Route path="addcategory/:id" element={<AddCategoryToProduct />} />
        </Route>
        {/* <Route path="allepinrequests">
              <Route index element={<AllEpinRequests />} />
              <Route path=":id" element={<EPinRequestDeails />} />
            </Route> */}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
