import React from "react";
import nfImage from "../../nf_new.gif";
const NotFound = () => {
  return (
    <div className="nf-container">
      <div className="nf_image_container">
        <img src={nfImage} alt="" className="nf_img" />
      </div>
    </div>
  );
};

export default NotFound;
