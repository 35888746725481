import React from "react";
import "./dashBoardCard.css";

const DashBoardCard = (props) => {
  return (
    <div className="card">
      <div className="icon-container">{props.icon}</div>
      <div className="content-container">
        <span>{props.amount}</span>
        <p className="card-head">{props.heading}</p>
      </div>
    </div>
  );
};

export default DashBoardCard;
