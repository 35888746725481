import React, { useEffect, useState } from "react";
import Navigation from "../../components/navigation/Navigation";
import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/Footer/Footer";
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

export const AddCategoryToProduct = () => {
  const [applicableDays, setApplicableDays] = useState(["mon", "tue"]);

  const handleChange = (event) => {
    const { value } = event.target;
    setApplicableDays(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div id="homepage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="card-container dashBoard_cardContainer">
            <div className="gcDetailsContainer">
              <form>
                <div className="input-group">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="applicable-days">Applicable Days</InputLabel>
                    <Select
                      labelId="applicable-days"
                      id="applicable-days"
                      multiple
                      value={applicableDays}
                      onChange={handleChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(",")}
                      MenuProps={MenuProps}
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={applicableDays.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </form>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
};
